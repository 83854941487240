@import 'styles/breakpoints';
@import 'styles/animations';

.root {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    justify-content: center;
    background-color: rgba(07, 16, 31, 50%);
    opacity: 0;
    pointer-events: none;
    transition: opacity $easeOutQuint $slow,
        visibility $easeOutQuint $slow $slow;
    visibility: hidden;

    &.isVisible {
        opacity: 1;
        pointer-events: auto;
        transition: opacity $easeOutQuint $slow, visibility $easeOutQuint $slow;
        visibility: visible;
    }
}

.overlay {
    display: flex;
    width: 100%;
    min-width: var(--overlay-min-width, none);
    max-width: var(--overlay-max-width, 32rem);
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.overlayInner {
    position: relative;
    display: var(--overlay-inner-display, inline);
    overflow: auto;
    width: 100%;
    border-radius: var(--border-radius, 2rem);
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 20%);
    opacity: 0;
    transition: opacity $easeOutQuint $slow,
        visibility $easeOutQuint $slow $slow;
    visibility: hidden;

    .isVisible & {
        opacity: 1;
        transition: opacity $easeOutQuint $superSlow * 2 0.2s,
            visibility $easeOutQuint $superSlow 0.2s;
        visibility: visible;
    }
}

.closeButton {
    position: absolute;
    z-index: 10;
    top: var(--close-button-top, 1rem);
    right: var(--close-button-right, 1rem);
    display: flex;
    overflow: visible;
    width: 3.6rem;
    height: 3.6rem;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;

    // button resets
    margin: 0;
    appearance: none;
    background: transparent;
    color: var(--close-button-color, var(--colors-blue));
    font: inherit;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    line-height: normal;
    outline: none;

    .hasImage & {
        border-radius: 50%;
        background-color: var(--colors-white);
    }
}

.closeIconWrapper {
    position: relative;
    width: var(--close-button-width, 1.6rem);
    height: var(--close-button-height, 1.6rem);

    > span {
        width: var(--close-button-width);
        height: var(--close-button-height);
    }
}

.aspectBox {
    position: relative;
    overflow: hidden;
    width: var(--aspect-box-width, 100%);
    height: 0;
    padding-top: var(--aspect-box-padding-top, 100%);

    &.imageAspectBox {
        padding-top: var(--aspect-box-padding-top, 68.75%);
    }
}

.image {
    position: absolute;
    top: 0;
    left: 0;
}

.imageLarge {
    display: none;
}

.content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--colors-white);
    color: var(--colors-blue);
}

.innerContent {
    position: relative;
    display: flex;
    width: 100%;
    max-width: var(--inner-content-max-width, none);
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--inner-content-padding, 3.5rem 3.2rem);
    text-align: center;
}

.iconWrapper {
    position: relative;
    width: auto;
    height: var(--icon-wrapper-height, 4rem);
}

.icon {
    img {
        width: auto;
        height: 4rem;
    }
}

.headlineWrapper {
    max-width: var(--headline-max-width, 25rem);
    margin-top: var(--headline-margin-top, 1.2rem);
}

.bodyCopyWrapper {
    max-width: var(--body-copy-max-width, 25rem);
    margin-top: var(--body-copy-margin-top, 1.2rem);
}

// rich text overrides
.headline {
    padding: 0;
    text-transform: uppercase;
}

.bodyCopy {
    padding: 0;
    margin: 0 0 0.8rem;

    &:last-child {
        margin: 0;
    }
}

.cta {
    margin-top: var(--cta-margin-top, 2rem);
}

// Hide images on small screens
@media (max-width: 374px) {
    .root {
        --overlay-min-width: 30rem;
    }

    .imageAspectBox {
        display: none;
    }

    .closeButton {
        .hasImage & {
            color: var(--close-button-color, var(--colors-blue));
        }
    }
}

@include large {
    .root {
        --overlay-max-width: 50rem;
        --overlay-inner-display: flex;
        --close-button-color: var(--colors-blue);
        --close-button-top: 3rem;
        --close-button-right: 3rem;
        --close-button-width: 2.2rem;
        --close-button-height: 2.2rem;
        --icon-wrapper-height: 5rem;
        --headline-margin-top: 2.4rem;
        --body-copy-margin-top: 2.4rem;
        --cta-margin-top: 2.4rem;
        --inner-content-padding: 0;
        --inner-content-max-width: 80%;
        --headline-max-width: 40rem;
        --body-copy-max-width: 32rem;
        --border-radius: 3rem;

        &.hasImage {
            --overlay-max-width: 100rem;
            --aspect-box-width: 50%;
            --aspect-box-padding-top: 50%;
        }
    }

    .imageSmall {
        display: none;
    }

    .imageLarge {
        display: block;
    }
}
