@import 'styles/breakpoints';
@import 'styles/animations';

@keyframes scale-mobile {
    from {
        opacity: 0;
        transform: scale(0) translate3d(-50%, 0, 0);
    }

    to {
        opacity: 1;
        transform: scale(1) translate3d(-50%, 0, 0);
    }
}

@keyframes scale {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes close {
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: 0;
        transform: scale(0);
    }
}

.root {
    position: fixed;
    z-index: var(--z-modal);
    bottom: var(--root-bottom, 3.6rem);
    left: 50%;
    width: 100%;
    max-width: var(--root-max-width, 32rem);
    border-radius: 2rem;
    animation: scale-mobile $default $easeOutQuad forwards;
    background-color: var(--colors-white);
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 20%);
    color: var(--colors-blue);
    transform-origin: bottom right;

    &.isClosing {
        animation: close $fast $easeOutQuad forwards;
    }
}

.card {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding: var(--card-padding, 3.6rem 3.2rem);
}

.root .closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.closeIconBuffer {
    padding: 2rem;
}

.closeIconWrapper {
    position: relative;
    width: var(--close-width, 2rem);
    height: var(--close-width, 2rem);
}

.icon {
    position: relative;
    width: var(--icon-width, 7rem);
    height: var(--icon-height, 7rem);
    margin-bottom: 2.4rem;
}

.headlineWrapper {
    margin-bottom: 1.1rem;
    text-align: center;
    text-transform: uppercase;
}

.bodyCopyWrapper {
    margin-bottom: var(--copy-padding, 2rem);
    text-align: center;

    p {
        margin-bottom: 0;
    }
}

@include medium {
    .root {
        right: 2rem;
        left: unset;
        animation: scale $default $easeOutQuad forwards;
    }
}

@include large {
    .root {
        --root-left: auto;
        --root-bottom: 2rem;
        --root-max-width: 44.6rem;
        --card-padding: 4.8rem 6rem;
        --icon-width: 8rem;
        --icon-height: 8rem;
        --close-width: 1.6rem;
        --close-height: 1.6rem;
        --copy-padding: 3.6rem;
    }
}
